import React,{useEffect,useState} from "react";


function Profile (props){

    let [nickname,setNickname] = useState("");
    let [fail,setFail] = useState();
    let [success,setSuccess] = useState();
    let apiUrl = process.env.REACT_APP_SERVER_URL;

    let userData = {
        username:props.userData.username,
        id:props.userData.id,
        autherized:props.userData.autherized,
        nickname:props.userData.nickname
    };
    useEffect(()=>{
        props.transferData(userData);
    },[1]);

    async function changeNickname(){
        let data = {
            id:props.userData.id,
            nickname:nickname
        }
        let result = await fetch (apiUrl+"/update/nickname",
        {
            method:"POST",
            headers:
            {
                "Content-Type": "application/json",        
            },
            body:JSON.stringify(data)
        }).then((response)=>{return response.json()}
        ).then((data)=>{
            if(data.error == false){
                setSuccess(data.message);
                setNickname(data.nickname);
                userData.nickname=data.nickname;
                document.getElementsByClassName("Profile-Heading").innerText = "Nickname : " + data.nickname;
                props.transferData(userData);
                setFail();
            }else{
                setFail(data.message);
                setSuccess();
            }
        })}

return( <>
<div className="Content">
<div className="Flex-Container Center">
    <div className="Profile-Background">
        <div className="Profile">
            <div className="Profile-Heading">
                <h2>Profile Settings</h2>
            </div>
            <div>
                <span className="Profile-DetailsBg"><p className="Profile-Details">Nickname : {userData.nickname}</p></span>
                <span className="Profile-DetailsBg"><p className="Profile-Details">E-mail : {userData.username}</p></span>
                <div className="Profile-Input-Container">
                    <input className="Profile-Input" placeholder={userData.nickname} onChange={(e)=> setNickname(e.target.value)}></input>    
                </div>
                <p style={{color: fail? "red":"green"}}>{ fail ||success  }</p>  
                <button className="Profile-Button" onClick={changeNickname}>Change Nickname</button>
                <button className="Profile-Button" onClick={props.logout}>Logout</button>
            </div>
        </div>
    </div>
</div>
</div>
</>)
}


export default Profile;