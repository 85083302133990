import React, {useEffect, useState}  from "react";
import Post from "./post";


function Kill (props){

    let [kills,setKills] = useState("");
    let [link,setLink] = useState("");
    let [respond,setRespond] = useState("");
    const apiUrl = process.env.REACT_APP_SERVER_URL;


    let userData = {
        username:props.userData.username,
        id:props.userData.id,
        autherized:props.userData.autherized,
        nickname:props.userData.nickname
    };
    useEffect(()=>{
        props.transferData(userData);
    },[1]);

   async function  handleClick(){
    let data = {
        id:props.userData.id,
        kills:kills,
        link:link
    }
    let result = await  fetch(apiUrl+ "/kill",
                                {
                                    method: "POST", 
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(data),
                                  }).then((response)=>{
                                    return response.json();
                                  }).then((data)=>{
                                        setRespond(data.message);
                                  });
    }
return(
    <div className="Content">
    <div className="Flex-Container">
    <div className="mba"> 
            <Post onClick={handleClick} kills={e=>setKills(e.target.value)} link={e=>setLink(e.target.value)} title="Kills" />
    </div>
    </div>
    </div>
);

};

export default Kill;