import React,{useEffect, useState} from "react";



function Login (props) {
    let [autherized,setAutherized] = useState(false);
    let [id,setId] = useState("0");
    let [username,setUsername] = useState("guest");
    let [password,setPassword] = useState("");
    let [nickname,setNickname] = useState("");
    let [errorMessage,setErrorMessage] = useState("");

    let userData = {
        username:username,
        id:id,
        nickname:nickname,
        autherized:autherized
    };
    const apiUrl = process.env.REACT_APP_SERVER_URL;
    // SubmitLogin function 
    async function submitLogin(event){
        event.preventDefault();
        let data={
            username:username, 
            password:password 
        }
        const result = await fetch(apiUrl+"/login",
                                        {
                                            method:"POST",
                                            headers:
                                            {
                                                "Content-Type": "application/json",        
                                            },
                                            body:JSON.stringify(data),
                                        }).then((response)=>{
                                            return response.json();
                                        }).then((data)=>{
                                            setId(data.id);
                                            setAutherized(data.autherized);
                                            setNickname(data.nickname);
                                            if(data.error === "user not registered pls register first"){
                                                setErrorMessage("User not found, pls register");
                                            }
                                        });                       
                       
    }
    useEffect(()=>{
        props.transferData(userData);
    },[id]);
    async function submitGoogle()
    {
        window.open(apiUrl+"/auth/google","_self");
    }

return(
<div className="Content">
<div  className="Flex-Container">
<div hidden={props.hidden} className="Login">
    <div className="Login-Background">
    <div className="Login-Form">
     <div className="Form-Container">
        <span className="Login-Text">Username:</span>
        <input type="email"  name="Username" required="True" onChange={e => setUsername(e.target.value)}></input>
     </div>   
    <div className="Form-Container">
    <span className="Login-Text">Password:</span>
        <input type="Password"  name="Password" required="True" onChange={e => setPassword(e.target.value)}></input>
    </div>
    <div className="Button-Container">
    <button className="Form-Container-Button" onClick={submitLogin} >Login</button>
    <button className="Form-Container-Button" onClick={submitGoogle}>Login with Google</button>
    <span className="Error-Message" style={{color:'red' , fontSize: '0.7rem'}}>{errorMessage}</span>  
    </div>
    </div>
    </div>
   </div>
   </div> 
</div>   
)
}

export default Login; 
