import React, { useEffect, useState } from "react";
import {BrowserRouter ,Link , Routes , Route} from "react-router-dom";
import Track from "./track";
import Shame from "./shame";
import Fame from "./fame";
import Login from "./login";
import Kill from "./kill";
import Teamkill from "./teamkill";
import Register from "./register";
import Profile from "./profile";
import Footer from "../footer";

function Header(){

   const [data, setData] = useState('');
   const [google,setGoogle] = useState([])
   const [hidden,setHidden] = useState(true)
   const [hideLogin,setHideLogin] =useState(false);
   const [isOpen,setOpen] = useState(false);
   const transferData = (data) => {
    setData(data);
   } 
   useEffect(()=>{
    if(data.autherized === true){
        setHidden(false)
        setHideLogin(true);
       } 
   },[data])

   let apiUrl = process.env.REACT_APP_SERVER_URL;
   
   async function checkGoogle(){
        try{
            let result = await fetch(apiUrl+"/google/success",{
                            method:"GET",
                            headers:{
                                "Content-Type": "application/json",
                            },
                            credentials:"include",
            }).then((response)=>{
                return response.json();
            }).then((data)=>{
               setData(data) 
             })
        }catch(err){
            console.log(err)
        }
   };
   useEffect(()=>{
    checkGoogle();
   if (data < 1){
    console.log("fail");
   }else{
    setHidden(false);
    setHideLogin(true);
    setData(google);
   }     
   },[]);

   async function logout(){
    window.open(apiUrl+"/google/logout","_self");
   };
   let openMenue = () => {
    setOpen((open) => !open);    
   }

return (
<BrowserRouter>
        <div className="Header mba tarkovFont">
            <h1>NoTeamNeeded Tarkov Center</h1>
        </div>
            <nav className="Navbar-Container">
            <button onClick={openMenue} className="nav-button mobile"><li className="Navbar-Item Mobile"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 100 100">
                <path fill="#a1d3a2" d="M17 45H83V55H17zM17 68H83V78H17zM17 22H83V32H17z"></path><path fill="#1f212b" d="M83 56H17c-.552 0-1-.448-1-1V45c0-.552.448-1 1-1h66c.552 0 1 .448 1 1v10C84 55.552 83.552 56 83 56zM18 54h64v-8H18V54zM83 79H17c-.552 0-1-.448-1-1V68c0-.552.448-1 1-1h66c.552 0 1 .448 1 1v10C84 78.552 83.552 79 83 79zM18 77h64v-8H18V77zM83 33H17c-.552 0-1-.448-1-1V22c0-.552.448-1 1-1h66c.552 0 1 .448 1 1v10C84 32.552 83.552 33 83 33zM18 31h64v-8H18V31z"></path><path fill="#1f212b" d="M73.5 29H17c-.276 0-.5-.224-.5-.5S16.724 28 17 28h56.5c.276 0 .5.224.5.5S73.776 29 73.5 29zM78.5 29h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.276 0 .5.224.5.5S78.776 29 78.5 29zM82.5 75h-56c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h56c.276 0 .5.224.5.5S82.776 75 82.5 75zM23.5 75h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.276 0 .5.224.5.5S23.776 75 23.5 75zM67.5 52h-45c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h45c.276 0 .5.224.5.5S67.776 52 67.5 52zM78.5 52h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.276 0 .5.224.5.5S78.776 52 78.5 52zM73.5 52h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.276 0 .5.224.5.5S73.776 52 73.5 52z"></path>
                </svg></li></button>
            <ul className={ isOpen ? 'Navbar isOpen' : "Navbar" }>
            <span className="Inventory-Background">
                <Link to="/profile"><button className="nav-button"  hidden={hidden}><li className="Navbar-Item">Profile</li></button></Link>
                <Link to="/Register"><button className="nav-button" hidden={hideLogin} ><li className="Navbar-Item">Register</li></button></Link>
                <Link to="/Login"><button className="nav-button" hidden={hideLogin} ><li className="Navbar-Item">Login</li></button></Link>
                <Link to="/Shame"><button className="nav-button"><li className="Navbar-Item">Hall of shame</li></button></Link>
                <Link to="/Fame"><button className="nav-button" ><li className="Navbar-Item">Hall of Fame </li></button></Link>
                <Link to="/Tracker"><button className="nav-button" hidden={hidden}><li className="Navbar-Item">Kappa Tracker</li></button></Link>
                <Link to="/Postk"><button className="nav-button" hidden={hidden}><li className="Navbar-Item">Post new Kill</li></button></Link>
                <Link to="/Posttk"><button className="nav-button" hidden={hidden}><li className="Navbar-Item">Post new Teamkill</li></button></Link>
                </span> 
            </ul>
        </nav>

    <Routes>
    <Route path="/profile" element={<Profile userData={data} logout={logout} transferData={transferData}/>}/>
        <Route path="/Login" element={<Login hidden={hideLogin} userData={data} transferData={transferData}/>} />
        <Route path="/Register" element={<Register hidden={hideLogin} userData={data} transferData={transferData}/>} />
        <Route path="/Shame" element={<Shame userData={data} transferData={transferData}/>} />
        <Route path="/Fame" element={<Fame userData={data} transferData={transferData} />} />
        <Route path="/Tracker" element={<Track userData={data} transferData={transferData}/>} />
        <Route path="/Posttk" element={<Teamkill userData={data} transferData={transferData}/>} />
        <Route path="/Postk" element={<Kill userData={data} transferData={transferData}/>} />      
    </Routes>
    <Footer/>
</BrowserRouter>
);}

export default Header;