import React,{useEffect, useState} from "react";
import completedImage from "../../img/completed.png";
function Track (props) {
    ///pass userData between components
    let userData = {
        username:props.userData.username,
        id:props.userData.id,
        autherized:props.userData.autherized,
        nickname:props.userData.nickname
    };
    useEffect(()=>{
        props.transferData(userData);
    },[1]);

            let [kappaQuests,setKappaQuests] = useState([]);
            let [userQuests,setUserQuests] = useState([]);
            let [cardItems,setCardItems] = useState([]);
            let [userItems,setUserItems] = useState([]);
            let [reRender,setReRender] = useState(1);

    const apiUrl = process.env.REACT_APP_SERVER_URL;

    async function getQuests () {
        const result = await fetch(apiUrl+"/kappatree",{
                                                method:"POST",
                                                headers:{
                                                    "Content-Type": "application/json",
                                                },
                                                body:JSON.stringify({username:userData.id})
                                                }).then((response)=>{
                                                    return response.json();
                                                }).then((data)=>{
                                                    setKappaQuests(data.allQuests);
                                                    setUserQuests(data.userQuests);     
                                                });                                        
    }
    //Bring Questsback 
    async function bringQuestback(event){
        let jsonData = { 
            questID : event.target.value,
            player: props.userData.id,
            completed : false
        }
        let result = await fetch (apiUrl+"/kappatree",{
                                method:"DELETE",
                                headers:{
                                    "Content-Type" : "application/json",
                                },
                                body:JSON.stringify(jsonData)
                                }).then((response)=>{
                                    return response.json();
                                });
                                getQuests();
                                setReRender(x => x + 1);
                        }
    //Update quest method
    async function updateQuest(event){
        let jsonData = {
            questID: event.target.value,
            player:props.userData.id,
            completed: true
        }
        let result = await fetch(apiUrl+"/kappatree",{
                                            method:"PUT",
                                            headers:{
                                                "Content-Type": "application/json",
                                            },
                                            body:JSON.stringify(jsonData)
        }).then((response)=>{
            return response.json();
        });
      getQuests();
      setReRender(x => x + 1);
        }
    useEffect(()=>{
        getQuests();
    },[1]);

    //Make the components re renderable so you can see that the quest got removed or added
    useEffect(()=>{
        setCardItems(openKappaQuests.map((item , i)=>{

            return  (
                <div key={i} className="Quest-Cards">
                    <div className="Quest-Body">
                        <h3 className="Quest-Header">{item.quest}</h3>
                        <h4 className="Quest-Trader">{item.trader}</h4>
                        <a className="Quest-Link" href={item.questlink}>Tarkov Wiki</a>
                        <button value={item.id} onClick={updateQuest} className="Quest-Button">Completed Quest</button>
                    </div>
                </div>
            );
            }));
        setUserItems(userQuests.map((item,i)=>{
            return  (
                <div key={i + userQuests.length}className="Quest-Cards">
                <div className="Quest-Body">
                    <h3 style={{textDecoration:"line-through"}}  className="Quest-Header">{item.quest}</h3>
                    <h4 className="Quest-Trader">{item.trader} <img height="70px" src={completedImage}></img></h4>
                    <a className="Quest-Link" href={item.questlink}>Tarkov Wiki</a>
                    <button value={item.id} onClick={bringQuestback} className="Quest-Button">Return Quest</button>
                </div>
            </div>    
                ); 
        }));
 
    },[reRender]);
     //Filter completed Quests von kappa quests 
    let completedQuestIds = userQuests.filter((userQuests) => userQuests.completed).map((userQuests) => userQuests.questid);
    let openKappaQuests = kappaQuests.filter((kappaQuest) => !completedQuestIds.includes(kappaQuest.id));

cardItems = openKappaQuests.map((item , i)=>{

    return  (
        <tr key={i} className="Quest-BodyRow">
                <td className="Quest-BodyItem">{item.quest}</td>
                <td id="Quest-Trader" className="Quest-BodyItem">{item.trader}</td>
                <td id="Quest-Wiki" className="Quest-BodyItem"><a className="Quest-Link" href={item.questlink}>Tarkov Wiki</a></td>
                <td className="Quest-BodyItem Quest-Active"><div className="Quest-Active">active!</div></td> 
               <td className="Quest-BodyItem"><button value={item.id} onClick={updateQuest} className="Quest-Button">Completed Quest</button></td> 
        </tr>
    );
    });
userItems = userQuests.map((item,i)=>{
    return  (
        <tr key={i + userQuests.length} className="Quest-BodyRow">
            <td style={{textDecoration:"line-through"}}  className="Quest-BodyItem">{item.quest}</td>
            <td id="Quest-Trader" className="Quest-BodyItem">{item.trader}</td>
            <td id="Quest-Wiki" className="Quest-BodyItem"><a className="Quest-Link" href={item.questlink}>Tarkov Wiki</a></td>
            <td className="Quest-BodyItem Quest-Completed"><div className="Quest-Completed">Completed</div></td>
            <td className="Quest-BodyItem"><button value={item.id} onClick={bringQuestback} className="Quest-Button">Return Quest</button></td>
    </tr>    
        ); 
});

return (
    <div className="Content">
    <div className="Flex-Container">
        <div className="Quest-Container">
        <div className="Quest-Background">
        <table className="Quest-Tracker">
            <thead className="Quest-TableHead">
            <tr className="Quest-HeadRow">
                <th className="Quest-HeadItem" >Task</th>
                <th id="Quest-Trader" className="Quest-HeadItem">Trader</th>
                <th id="Quest-Wiki" className="Quest-HeadItem">Link</th>
                <th className="Quest-HeadItem">Status</th>
                <th className="Quest-HeadItem">Button</th>
            </tr>
            </thead>
            <tbody className="Quest-TableBody">
                {cardItems}
            </tbody>
        </table>
        </div>
        <div className="Quest-Background">
        <table className="Quest-Tracker">
            <thead className="Quest-TableHead">
            <tr className="Quest-HeadRow">
                <th className="Quest-HeadItem" >Task</th>
                <th id="Quest-Trader" className="Quest-HeadItem">Trader</th>
                <th id="Quest-Wiki" className="Quest-HeadItem">Link</th>
                <th className="Quest-HeadItem">Status</th>
                <th className="Quest-HeadItem">Button</th>
            </tr>
            </thead>
            <tbody className="Quest-TableBody">
                {userItems}
            </tbody>
        </table>
        </div>
        </div> 
        </div>
        </div>
);}

export default Track; 