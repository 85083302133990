import React from "react";

function Footer () {

let date = new Date();
let currentYear = date.getFullYear();

return(
    <div className="footer-container">
        <footer className="footer">
            <b className="footer">© Robert Saliger {currentYear}</b>
        </footer>
    </div>
);


};

export default Footer;