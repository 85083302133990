import React,{useEffect, useState} from "react";

function Register(props){
    
let [autherized,setAutherized] = useState(false);
let[nickname,setNickname] = useState("")
let [id,setId] = useState("0");
let [username,setUsername] = useState("guest");
let[password,setPassword] = useState("");
let[hidden,setHidden] = useState(false);
let[errorMessage,setErrormessage] = useState('');


let apiUrl = process.env.REACT_APP_SERVER_URL;

let userData = {
    username:username,
    id:id,
    autherized:autherized,
    nickname:nickname
};
async function submitRegister (event){
    event.preventDefault();
    let data={
        nickname:nickname,
        username:username, 
        password:password 
    }
    const result = await fetch(apiUrl+"/register",
                                    {
                                        method:"POST",
                                        headers:
                                        {
                                            "Content-Type": "application/json",        
                                        },
                                        body:JSON.stringify(data),
                                    }).then((response)=>{
                                        return response.json();
                                    }).then((data)=>{
                                        console.log(data);
                                        setAutherized(data.autherized);
                                        setId(data.id);
                                        setHidden(true);
                                        if(data.error === true){
                                            setErrormessage(data.message);
                                        }
                                    });
}

// Pass Login ID and status to the other components
useEffect(()=>{
    props.transferData(userData);
},[id]);

async function submitGoogle()
    {
        window.open(apiUrl+"/auth/google","_self");
    }

return(
<div className="Content">
<div className="Flex-Container">
<div hidden={props.hidden} className="Login">
    <div className="Register-Background">
    <form className="Register-Form">
        <div className="Form-Container">
        <span className="Login-Text">Nickname:</span>
        <input type="text" name="Nickname" required onChange={e => setNickname(e.target.value)}></input>     
        </div>
     <div className="Form-Container">
     <span className="Login-Text">Username:</span>
     <input type="email"  name="Username" required onChange={e => setUsername(e.target.value)}></input>
     </div>   
    <div className="Form-Container">
    <span className="Login-Text">Password:</span>
        <input type="Password" name="Password" required onChange={e => setPassword(e.target.value)}></input>
    </div>
    <div className="Button-Container">
    <button className="Form-Container-Button"  onClick={submitRegister} >Register</button>
    <button className="Form-Container-Button" onClick={submitGoogle}>Login with Google</button>      
    <span style={{color:'red', fontSize:'0.7rem'}}>{errorMessage}</span>
    </div>
    </form>
    </div>
    </div>
</div> 
    
</div>
);

}

export default Register; 