import React , {useState,useEffect} from "react";
import Post from "./post";

function Teamkill (props){

    let [kills,setKills] = useState("");
    let [link,setLink] = useState("");
    let [respond,setRespond] = useState("");

    let userData = {
        username:props.userData.username,
        id:props.userData.id,
        autherized:props.userData.autherized,
        nickname:props.userData.nickname
    };
    useEffect(()=>{
        props.transferData(userData);
    },[1]);
    const apiUrl = process.env.REACT_APP_SERVER_URL;
   async function handleClick(){

    let data = {
        id:props.userData.id,
        kills:kills,
        link:link
    }
        let result = await  fetch(apiUrl+"/teamkill",
        {
            method: "POST", 
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }).then((response)=>{
            return response.json();
          }).then((data)=>{
                setRespond(data.message);
          });
    }
return(    
<div className="Content">
<div className="Flex-Container">
        <Post onClick={handleClick} kills={e=>setKills(e.target.value)} link={e=>setLink(e.target.value)} title="Teamkills" />
</div>
</div>
)

};

export default Teamkill;