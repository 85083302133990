import React from "react";

function Post (props){
return(
    <div className="Content">
    <div className="Flex-Container">
    <div className="Container">
                <div className="Post-Background">
            <div className="Post">
            <div className="Post-Item">
                <h2>Post your {props.title}</h2>
            </div>
            <div className="Post-Item">
                <label> 
                <p>Number of {props.title}</p> 
                <input type="number" name={props.title} onChange={props.kills}></input>
                </label>
            </div>
            <div className="Post-Item">
                <label>
                <p>Post link to clip</p> 
                <input type="text" name={props.title + "link"} onChange={props.link}></input>
                </label>
            </div>
            <button className="Post-Button" type="submit" onClick={props.onClick}>Post</button>
            </div>
        </div>
    </div>
    </div>
    </div>
);

};

export default Post;