import React from "react";
import Header from "./components/pages/header";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';


function App (){

return(
    <>
    <Header/>
    </>
);
}

export default App;